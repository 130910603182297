import React from "react";
import { useGlobal } from "reactn";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { RouteStatus } from "@ugg/shared/components/common/RouteStatus";
import loadable from "@loadable/component";
import classnames from "classnames";

import DisabledRoute from "components/DisabledRoute";
import PremiumFAQ from "components/Accounts/PremiumFAQ";
import OpenSearchRedirect from "components/OpenSearchRedirect";
import Error404 from "components/Error404";

import AdWhitelist from "components/Ads/AdWhitelist";
import Login from "components/Accounts/Login";
import Signup from "components/Accounts/Signup";
import VerificationContainer from "components/Pages/VerificationPage/views/VerificationContainer";
import NotFoundUserSummonerProfile from "components/Accounts/NotFoundUserSummonerProfile/views/NotFoundUserSummonerProfile";
import MultiSearchMain from "components/MultiSearch/MultiSearchMain";
import { getSpecialTierLists } from "lib/specialGameMode";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { RiotIdRedirect } from "@ugg/shared/components/SummonerProfiles/RiotIdRedirect";
import Footer from "@ugitgud/legos/ui/footer/Footer";

// Loadable Components
const LandingPageContainer = loadable(() => import("components/Pages/LandingPage/LandingPageContainer"));
const DesktopAppSplashPage = loadable(() => import("components/DesktopAppSplashPage/DesktopAppSplashPage"));
const SupportUsPage = loadable(() => import("components/Pages/SupportUs/SupportUsPage"));
const LeaderboardsPage = loadable(() => import("components/Leaderboards/LeaderboardsPage"), {
  resolveComponent: (components) => components.LeaderboardsPage,
});
const TierListPage = loadable(() => import("components/TierListPage"), {
  resolveComponent: (components) => components.TierListPage,
});
const Champions = loadable(() => import("components/Champions"));
const SummonerProfile = loadable(() => import("components/SummonerProfiles/SummonerProfile"), {
  resolveComponent: (components) => components.SummonerProfile,
});
const Settings = loadable(() => import("components/Accounts/Settings/Settings"));
const RecoverAccount = loadable(() => import("components/Accounts/RecoverAccount/RecoverAccount"));
const PasswordReset = loadable(() => import("components/Accounts/PasswordReset/PasswordReset"));
const About = loadable(() => import("components/Pages/About/About"));
const FAQ = loadable(() => import("components/Pages/FAQ"));
const PrivacyPolicy = loadable(() => import("components/PrivacyPolicy"));
const PrivacyShield = loadable(() => import("components/PrivacyShield"));
const TermsOfService = loadable(() => import("components/TermsOfService"));
const Careers = loadable(() => import("components/Careers"));
const PremiumCheckout = loadable(() => import("components/Premium/views/PremiumCheckout"));
const LiveGameSplash = loadable(() => import("components/SummonerProfiles/LiveGame/LiveGameSplash"), {
  resolveComponent: (components) => components.LiveGameSplash,
});
const EmailVerificationPage = loadable(() => import("components/Pages/EmailVerificationPage/EmailVerificationPage"));
const Items = loadable(() => import("components/Items/Items"));

const MainContent = (props) => {
  const [expandedSideNav] = useGlobal("expandedSideNav");
  const [verificationBar] = useGlobal("verificationBar");
  const location = useLocation();

  const mainContentClassNames = classnames({
    "verification-bar-active": verificationBar,
    collapsed: !expandedSideNav,
    expanded: expandedSideNav,
  });

  const discordLink = "2nDpSxmU";
  const twitterLink = "UdotGG";
  const youtubeLink = "user/enthusiastgamingch";
  const instagramLink = "UdotGG";
  const facebookLink = "UdotGG";

  const disclaimerText =
    "U.GG Stats isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.";

  let tierLists = [
    "/lol/tier-list",
    "/lol/top-lane-tier-list",
    "/lol/jungle-tier-list",
    "/lol/mid-lane-tier-list",
    "/lol/adc-tier-list",
    "/lol/support-tier-list",
    "/lol/duo-tier-list",
    "/lol/combat",
    "/lol/objectives",
    AppRoutes.SPECIAL_GAME_MODE_TIER_LIST,
  ].concat(getSpecialTierLists());

  return (
    <div id="main-content" className={mainContentClassNames} ref={props.forwardedRef}>
      <div id="content-wrapper" style={{ paddingLeft: location.pathname === "/about" ? "0px" : "" }}>
        <div id="content" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Switch>
            <Route exact path="/" component={LandingPageContainer} />
            <Route exact path="/app" component={DesktopAppSplashPage} />
            <Route exact path="/support-us" component={SupportUsPage} />
            <Redirect from="/discover-boost" to="/support-us" />
            <Route exact path="/lg-splash" component={LiveGameSplash} />
            <Route path="/verify" component={EmailVerificationPage} />
            <DisabledRoute path="/not-found-user-summoner-profile" component={NotFoundUserSummonerProfile} />
            <Route path={AppRoutes.PROFILE_ROOT} component={SummonerProfile} />
            <Route
              path={AppRoutes.PROFILE_ROOT_OLD}
              component={RiotIdRedirect}
              /* Must come after AppRoutes.PROFILE_ROOT */
            />
            <Route path={tierLists} component={TierListPage} />
            <Route
              path={["/lol/champions", "/lol/champion-leaderboards", "/lol/:language?/champions"]}
              render={() => <Champions />}
            />
            <Route path="/lol/items" render={() => <Items />} />
            <Route path="/leaderboards" component={LeaderboardsPage} />
            <Route path="/terms-of-service" render={(props) => <TermsOfService {...props} />} />
            <Route path="/ad-whitelist" render={(props) => <AdWhitelist {...props} />} />
            <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
            <Route path="/privacy-shield" render={(props) => <PrivacyShield {...props} />} />
            <Route path="/careers" render={(props) => <Careers {...props} />} />
            <Route path="/opensearch" render={(props) => <OpenSearchRedirect {...props} />} />
            <Route path="/multisearch" render={(props) => <MultiSearchMain {...props} />} />
            <DisabledRoute path="/settings" component={Settings} />
            <Route path="/recover-account" component={RecoverAccount} />
            <Route path="/reset-password" component={PasswordReset} />
            <Route path="/about" render={(props) => <About {...props} />} />
            <Route path="/faq" render={(props) => <FAQ {...props} />} />
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route path="/signup" render={(props) => <Signup {...props} />} />
            <DisabledRoute path="/boost/payment" component={PremiumCheckout} />
            <Redirect from="/boost(/*)?" to="/boost/payment" />
            <DisabledRoute path="/boost-faq" render={(props) => <PremiumFAQ {...props} />} />
            <Route path="/verification" render={(props) => <VerificationContainer />} />
            <RouteStatus statusCode={404} path="*">
              <Error404 />
            </RouteStatus>
          </Switch>
        </div>
        {location.pathname !== "/about" && (
          <Footer
            discordLink={discordLink}
            twitterLink={twitterLink}
            youtubeLink={youtubeLink}
            instagramLink={instagramLink}
            facebookLink={facebookLink}
            disclaimerText={disclaimerText}
            path={location.pathname}
          />
        )}
      </div>
    </div>
  );
};

export default MainContent;
