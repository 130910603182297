import React from "react";
import { GameNavButtonGroup, GameNavButtonGroupProps } from "@ugitgud/legos/components/nav/GameNav/GameNavButtonGroup";
import { GameNavButton } from "@ugitgud/legos/components/nav/GameNav/GameNavButton";
import { NavTag } from "@ugitgud/legos/ui/form/tag/NavTag";
import { SvgGameValGrey } from "@ugitgud/legos/ui/icon/SVG/GameValGrey";
import { SvgGameLolGrey } from "@ugitgud/legos/ui/icon/SVG/GameLolGrey";
import { SvgGameLol } from "@ugitgud/legos/ui/icon/SVG/GameLol";
import { SvgGameVal } from "@ugitgud/legos/ui/icon/SVG/GameVal";
import { SvgGameTft } from "@ugitgud/legos/ui/icon/SVG/GameTft";
import { SvgGameWow } from "@ugitgud/legos/ui/icon/SVG/GameWow";
import { SvgGameWowGrey } from "@ugitgud/legos/ui/icon/SVG/GameWowGrey";
import { SvgGameTftGrey } from "@ugitgud/legos/ui/icon/SVG/GameTftGrey";
import { SvgGameHelldiversGrey } from "@ugitgud/legos/ui/icon/SVG/GameHelldiversGrey";
import { SvgGameHelldivers } from "@ugitgud/legos/ui/icon/SVG/GameHelldivers";
import { SvgGame2XkoGrey } from "@ugitgud/legos/ui/icon/SVG/Game2XkoGrey";
import { SvgGame2Xko } from "@ugitgud/legos/ui/icon/SVG/Game2Xko";
import classNames from "classnames";
import { SvgGameSwarmGrey } from "@ugitgud/legos/ui/icon/SVG/GameSwarmGrey";
import { SvgGameSwarm } from "@ugitgud/legos/ui/icon/SVG/GameSwarm";

export const GameNav: React.FC<Pick<GameNavButtonGroupProps, "selectedGame" | "className">> = ({ selectedGame, className }) => {
  return (
    <div
      className={classNames(
        "w-full overflow-x-auto min-h-[31px] h-[31px] font-inter overflow-y-hidden dark:bg-purple-500 pointer-events-auto sb-hide border-b-purple-400 border-b",
        className,
      )}
    >
      <GameNavButtonGroup className={classNames("h-[30px]")} selectedGame={selectedGame}>
        <GameNavButton
          game="lol"
          icon={<SvgGameLolGrey />}
          iconToggled={<SvgGameLol />}
          href="https://u.gg/?utm_source=uggtopnav"
        >
          League of Legends
        </GameNavButton>
        <GameNavButton
          game="val"
          icon={<SvgGameValGrey />}
          iconToggled={<SvgGameVal />}
          href="https://u.gg/val?utm_source=uggtopnav"
        >
          Valorant{" "}
          <NavTag shrink={false} size="sm" colorScheme="primary">
            NEW
          </NavTag>
        </GameNavButton>
        <GameNavButton
          game="tft"
          icon={<SvgGameTftGrey />}
          iconToggled={<SvgGameTft />}
          href="https://u.gg/tft?utm_source=uggtopnav"
        >
          Teamfight Tactics{" "}
          <NavTag shrink={false} size="sm" colorScheme="primary">
            SET 12
          </NavTag>
        </GameNavButton>
        <GameNavButton
          game="tockers"
          icon={<SvgGameTftGrey />}
          iconToggled={<SvgGameTft />}
          href="https://u.gg/tft/tockers-trials/comps?utm_source=uggtopnav"
        >
          Tockers Trials
          <NavTag shrink={false} size="sm" colorScheme="primary">
            NEW
          </NavTag>
        </GameNavButton>
        <GameNavButton
          game="wow"
          icon={<SvgGameWowGrey />}
          iconToggled={<SvgGameWow />}
          href="https://u.gg/wow?utm_source=uggtopnav"
        >
          World of Warcraft
        </GameNavButton>
        <GameNavButton
          game="helldivers"
          icon={<SvgGameHelldiversGrey />}
          iconToggled={<SvgGameHelldivers />}
          href="https://u.gg/hd2?utm_source=uggtopnav"
        >
          Helldivers 2
        </GameNavButton>
        <GameNavButton
          game="2xko"
          icon={<SvgGame2XkoGrey />}
          iconToggled={<SvgGame2Xko />}
          href="https://u.gg/2xko?utm_source=uggtopnav"
        >
          2XKO{" "}
          <NavTag shrink={false} size="sm" colorScheme="primary">
            SOON™
          </NavTag>
          {/* <NavTag shrink={false} size="sm" colorScheme="tertiary">
            SOON™
          </NavTag> */}
        </GameNavButton>
      </GameNavButtonGroup>
    </div>
  );
};
